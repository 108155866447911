<template>
    <contact-vue/>
</template>

<script>

import ContactVue from "@/views/desktop/Contact.vue";

export default {
    name: 'contact-mobile-vue',
    components: {ContactVue},
}
</script>

<style lang="scss">
#mobile .contact-form {
    h1 {
        margin-top: 0;
    }

    form {
        margin-top: 25px;
    }
}
</style>