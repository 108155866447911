<template>
    <div id="subMenu" class="menu hidden t500" ref="subMenu" :key="$route.meta.id">
        <router-link v-for="route in routes[$route.meta.id]" class="t500" :to="'/' + route" :key="route">
            {{ $t(route.replace("/", ".") + ".name") }}
        </router-link>
    </div>
</template>

<script>

import configs from "@/globals/configs.json";

export default {
    name: 'sub-menu-mobile-vue',
    data() {
        return {
            routes: configs.navigation
        }
    },
    watch:{
        '$route.meta.id'(){
            this.open();
        }
    },
    mounted() {
        this.open();
    },
    methods:{
        open(){

            setTimeout(() => {
                this.$refs.subMenu.classList.remove("hidden")
            }, 200)
        }
    }
}
</script>

<style lang="scss">
#subMenu {
    opacity: 1;

    &.hidden {
        opacity: 0;
    }

    a[aria-current="page"] {
        color: var(--color-accent);
    }
}
</style>
