<template>
    <div id="pop" class="dark-background">
        <div class="main">
            <div class="left-side">
                <text-component :first-word="firstWord($t('works.deutschePOP.name'))">
                    <template #normal>
                        {{ firstWord($t('works.deutschePOP.name')) }}
                    </template>
                    <template #accent>
                        {{ restText($t('works.deutschePOP.name')) }}
                    </template>
                </text-component>
                <p class="description" v-html="$t('works.deutschePOP.description')"/>
            </div>
        </div>
        <scroll-component :videos="videos.scroll" :title="$t(videos.title)" v-for="(videos, row) in gallery" :key="'row'+row"/>
    </div>
</template>

<script>
import configs from "@/globals/configs.json";
import TextComponent from "@/components/dekstop/Text.vue";
import ScrollComponent from "@/components/dekstop/Scroll.vue";

export default {
    name: 'pop-vue',
    components: {ScrollComponent, TextComponent},
    data() {
        return {
            gallery: configs.videos.pop,
        }
    },
    methods: {
        firstWord(text) {
            return text.trim().split(' ').shift();
        },
        restText(text) {
            const words = text.trim().split(' ');
            words.shift();
            return " " + words.join(' ');
        },
    }
}
</script>

<style lang="scss">
#pop {
    .description {
        width: 509px;
        font-weight: 400;
    }

    > .main {
        width: 1920px;
        margin: auto;

        text {
            text-transform: uppercase;
        }

        .left-side {
            width: 500px;
            max-width: 500px;
            margin-left: 30%;
        }
    }

    .accent {
        color: white;
    }

}
</style>
