<template>
    <pop-vue/>
    <top-component/>
</template>

<script>

import PopVue from "@/views/desktop/works/Pop.vue";
import TopComponent from "@/components/dekstop/Top.vue";
import AmpiriaVue from "@/views/desktop/works/Ampiria.vue";

export default {
    name: 'pop-mobile-vue',
    components: {AmpiriaVue, TopComponent, PopVue},
}
</script>

<style lang="scss">
#mobile #pop {

    .main {
        display: block;
        width: 100%;
        margin: auto auto 10vw;
        max-width: 500px;

        .description {
            width: unset;
        }
    }

    .scroll-component {
        max-width: 90vw;
        margin: 10vw auto;

        .scroll-elements .video-component video.preview {
            width: 100%;
            max-height: 70vh;
        }
    }

}
</style>
