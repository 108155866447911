<template>
    <div id="home" :class="hide">
        <div class="text">
            <div class="accent">
                <h2 class="first-line b300 t1000">
                    {{ $t("hello") }}
                </h2>
                <h3 class="second-line b300 t1000">
                    {{ $t("iam") }}
                </h3>
                <carousel-component :interval="3000">
                    <h1 class="third-line t600 t1000" v-for="(header, index) in headers" :key="index">
                        {{ $t(header) }}
                    </h1>
                </carousel-component>
            </div>
            <p class="t1000 description">{{ $t("welcome") }}</p>
        </div>
        <img-component class="t300 main" src="website/Edgar.png" alt="edgar"/>
        <showreels-vue/>
    </div>
</template>

<script>


import CarouselComponent from "@/components/dekstop/Carousel.vue";
import configs from "@/globals/configs.json";
import BackgroundComponent from "@/components/dekstop/Background.vue";
import ShowreelsVue from "@/views/desktop/start/Showreels.vue";
import ImgComponent from "@/components/dekstop/Img.vue";

export default {
    name: 'start-vue',
    components: {ImgComponent, ShowreelsVue, BackgroundComponent, CarouselComponent},
    data() {
        return {
            headers: configs.navigation.headers,
            hide: "hide"
        }
    },
    mounted() {
        const self = this;
        setTimeout(function () {
            self.hide = "";
        }, 100);
    },
}
</script>

<style lang="scss">

#home {
    opacity: 1;
    margin: 20px auto;
    width: 1150px;
    position: relative;
    h2{
        text-transform: uppercase;
        color: var(--color-accent);
    }

    &.hide {
        * {
            opacity: 0;
        }

        .first-line {
            margin-left: -60px;
        }

        .second-line {
            margin-left: -40px;
        }

        .third-line {
            margin-left: -20px;
        }

        p {
            margin-left: -50px;
        }

        img.main {
            opacity: 0;
            margin: -600px 0 50px 660px;
        }

    }

    p.description {
        margin-top: 46px;
        color: var(--color-text);
        max-width: 580px;
        line-height: 24px;
        font-size: 20px;
        width: 580px;
    }

    img.main {
        transition: all 2s ease-in-out;
        opacity: 1;
        mix-blend-mode: color-dodge;
        height: 1052px;
        margin: -600px 0 50px 560px;
    }

    .accent {
        white-space: nowrap;

        .first-line {
            font-size: 50px;
            line-height: 80px;
            margin-left: 20px;
            margin-bottom: 11px;
        }

        .second-line {
            text-transform: none;
            font-size: 32px;
            line-height: 38px;
            margin-left: 71px;
            margin-bottom: 12px;
        }

        .third-line {
            line-height: 100px;
            font-size: 65px;
            left: -22px;
        }
    }
}
</style>