<template>
    <div class="switch" ref="switch">
        <div class="control t500 flex">
            <div @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" @click="handleClick" class="t300 control-element"
                 :data-key="key" :data-id="i"
                 v-for="(data, key, i) in switchData" :key="key">
                {{ $t(data.key) }}
            </div>
            <div ref="lines" class="lines t500">
                <div class="line-left t500"></div>
                <div class="line-middle t500"></div>
                <div class="line-right t500"></div>
            </div>
        </div>
        <div class="slotContainer">
            <div v-for="(data, key) in switchData" :key="key" :class="key + ' slot flex t1000'">
                <slot :name="key">
                    <img-component class="t300 control-img" :data-ref="key"
                         :src="data.data" />
                </slot>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'switch-component',
    data() {
        return {
            currentIndex: 0,
            active: undefined,
        }
    },
    props: {
        switchData: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        this.handleClick(undefined, this.$refs.switch.querySelector(".control-element"))
    },
    methods: {
        handleMouseEnter(event) {
            const element = event.currentTarget;
            element.classList.add("hover");
            this.handleLines(element.dataset['id']);
        },
        handleMouseLeave(event) {
            const element = event.currentTarget;
            element.classList.remove("hover");
            this.handleLines(this.active ? this.active.dataset['id'] : "0");
        },
        handleClick(event, el = undefined) {
            const element = el ? el : event.currentTarget;
            this.active?.classList?.remove("active");
            this.active = element;
            element.classList.add("active");
            element.closest(".switch").querySelector('.slot.active')?.classList.remove("active")
            element.closest(".switch").querySelector("." + element.dataset['key'] + ".slot").classList.add("active")
        },
        handleLines(index) {
            this.$refs.lines?.querySelector(".line-left")?.style.setProperty("width", 30 + parseInt(index) * 40 + "%");
            this.$refs.lines?.querySelector(".line-right")?.style.setProperty("width", 70 - parseInt(index) * 40 + "%");
        }
    }
}
</script>

<style lang="scss">


.switch {
    position: relative;
    margin: auto;
    display: block;

    .control {
        z-index: 15;
        bottom: 100px;
        left: 0;
        position: relative;
        margin: auto;
        width: 100vw;
        max-width: 2000px;
        justify-content: center;

        .control-element {
            width: 20%;
            text-align: center;
            cursor: pointer;
            margin: 0 10%;
        }
    }

    .slotContainer {
        display: grid;
        justify-content: center;

        .slot {
            grid-area: 1/1;
            opacity: 0;
            > * {
                margin: auto;
            }
            &.active {
                opacity: 1;
                margin: auto;
            }
        }
    }

    .lines {
        position: absolute;
        display: flex;
        bottom: -40px;
        width: 100%;

        .line-left, .line-middle, .line-right {
            border: 1px solid var(--color-primary);
            border-radius: 2px;
            height: 0;
            margin: 0 1.5px;
            width: fit-content;
        }

        .line-left {
            position: relative;
            width: 30%;
            border-image: linear-gradient(to left, var(--color-primary), transparent) 2;
        }

        .line-middle {
            width: 20px;
            position: relative;
            height: 20px;
            rotate: 315deg;
            border-width: 2px;
            border-left: none;
            border-bottom: none;
            border-radius: 0;
            bottom: 10px;

            &.click {
                border-width: 20px;
            }
        }

        .line-right {
            position: relative;
            width: 70%;
            border-image: linear-gradient(to right, var(--color-primary), transparent) 2;
        }
    }
}

</style>
