import {createI18n} from 'vue-i18n'
import configs from "./globals/configs.json";
import axios from "axios";

export function saveLanguage(language) {
  localStorage.setItem(configs.localStorage.language, language)
}

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /\w\w\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/(\w\w)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

export const i18n = createI18n({
  locale: localStorage.getItem(configs.localStorage.language) || 'de',
  fallbackLocale: 'de',
  messages: loadLocaleMessages()
})

function stringToObject(path, value, obj) {
  var parts = path.split("."), part;
  var last = parts.pop();
  while (part = parts.shift()) {
    if (typeof obj[part] != "object") obj[part] = {};
    obj = obj[part]; // update "pointer"
  }
  obj[last] = value;
}

function loadMessages() {
  configs.languages.forEach(language => {
    let response = axios.get(`${configs.api.url}locale/?format=json&iso_code=${language}`, {
      validateStatus: false,
      auth: {
        username: configs.api.user,
        password: configs.api.pw,
      }
    }).then(response => {
      if (response.status < 400 || response?.name !== "AxiosError") {
        let messages = {}
        if (response.data[Symbol.iterator] === 'function') {
          response.data.forEach(data => {
            return stringToObject(data.key, data.value, messages)
          })
          i18n.global.setLocaleMessage(language, messages)
        }
      }
    })
  })
}

loadMessages()