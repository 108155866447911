<template>
    <div id="impress">
        <h1>{{ $t("impress.name") }}</h1>
        <p class="max-width" v-html="$t('impress.data')"/>
    </div>
</template>

<script>

export default {
    name: 'impress-vue',
}
</script>

<style lang="scss">
#impress {
    max-width: 1000px;
    margin: auto;

    h1 {
        margin: 0 0 154px 0;
        font-size: 80px;
        font-weight: 300;
        line-height: 96px;
    }
    h2{
        margin: 0;
        font-size: 30px;
    }

    p {
        margin: 0 0 300px 0;

        a {
            font-weight: bolder;
        }
    }
}
</style>