<template>
    <div ref="carousel" class="carousel">
        <slot/>
    </div>
</template>


<script>

import data from "@/views/desktop/Data.vue";

export default {
    name: 'carousel-component',
    computed: {
        data() {
            return data
        }
    },
    data() {
        return {
            currentIndex: 0,
            intervalId: undefined,
            itemsSize: 0,
        }
    },
    props: {
        interval: {
            type: Number,
            default: 5000,
        },
        data: {
            default: undefined,
        },
    },
    beforeUnmount() {
        clearTimeout(this.intervalId);
    },
    mounted() {
        this.$refs.carousel.children[0]?.classList.add("active");
        this.itemsSize = this.data ? this.data.length : this.$refs.carousel.children.length;
        this.startCarousel();

        if (this.data) {
            let firstChild = this.$refs.carousel?.children[0];
            if (firstChild) {
                firstChild.innerHTML = this.$t(this.data[this.currentIndex]);
            }
        }
    },
    methods: {
        startCarousel() {
            this.intervalId = setInterval(() => {
                const self = this;
                this.currentIndex = (this.currentIndex + 1) % this.itemsSize;
                if (this.data) {
                    let firstChild = this.$refs.carousel?.children[0];
                    if (firstChild) {
                        firstChild.innerHTML = this.$t(this.data[this.currentIndex]);
                    }
                } else {
                    Array.from(this.$refs.carousel?.children).forEach((node, index) => {
                        if (index === self.currentIndex)
                            node.classList.add("active");
                        else
                            node.classList.remove("active")
                    });
                }
            }, this.interval);
        },
    }
}
</script>

<style lang="scss">


.carousel > * {
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    display: block;

    &.active {
        opacity: 1;
        position: relative;
        left: 0;
    }
}

#mobile .carousel {
    max-width: 100%;

    img {
        max-width: 100%;
    }
}
</style>
