<template>
    <div id="astrait" class="dark-background">
        <div class="main">
            <div class="left-side">
                <h2>{{ $t("works.astrait.name") }}</h2>
                <p v-html="$t('works.astrait.description')"/>
            </div>
            <div class="right">
                <img-component src="astrait/49,1.png" class="rocket"/>
                <img-component @mouseenter="handleRocketOver" @mouseleave="handleRocketOut" class="t500 rocket" src="astrait/46,1.png"
                     />
            </div>
        </div>
        <img-component class="wide" src="astrait/Eskimo" />
        <div class="horizontal-line">
            <h2>{{ $t("works.astrait.renderings") }}</h2>
        </div>


        <div class="flex between">
            <div class="hovered">
                <img-component src="astrait/Eskimo_12.jpg"  class=""/>
                <img-component @mouseenter="handleRocketOver" @mouseleave="handleRocketOut" class="t500 abs" src="astrait/Eskimo_11.jpg"
                     />
            </div>
            <carousel-component class="hovered">
                <img-component v-for="(img, i) in slideshow" :key="`speaker${i}`" :src="img"
                     class="t1000 abs" :alt="img"/>
            </carousel-component>
        </div>
        <interactive-component :data="interactiveData" :video="interactiveVideo"/>
        <img-component class="wide video" src="astrait/19,1.png" />
        <video-component :preview="moon" class="moon"/>
    </div>
</template>

<script>
import configs from "@/globals/configs.json";
import CarouselComponent from "@/components/dekstop/Carousel.vue";
import InteractiveComponent from "@/components/dekstop/Interactive.vue";
import VideoComponent from "@/components/dekstop/Video.vue";
import ImgComponent from "@/components/dekstop/Img.vue";

export default {
    name: 'astrait-vue',
    components: {ImgComponent, VideoComponent, InteractiveComponent, CarouselComponent,},
    data() {
        return {
            showRocketInterval: undefined,
            slideshow: configs.images.astrait.slideshow,
            interactiveData: configs.images.astrait.interactive,
            interactiveVideo: configs.videos.astrait.interactive,
            moon: configs.videos.astrait.moon,
        }
    },
    methods: {
        handleRocketOver(event) {
            clearTimeout(this.showRocketInterval);
            event.target.classList.add("hide");
        },
        handleRocketOut(event) {
            this.showRocketInterval = setInterval(() => {
                event.target.classList.remove("hide");
            }, 500);
        }
    }
}
</script>

<style lang="scss">


#astrait {
    position: relative;

    .left-side {
        width: 500px;
        min-width: 500px;
        margin-left: 30%;
    }

    .horizontal-line {
        margin: 250px auto 80px;
    }

    img.hide {
        opacity: 0 !important;
    }

    .right {
        float: right;
        position: relative;
        z-index: 1;
        width: 100%;

        > img {
            position: absolute;
            width: 360px;
            opacity: 1;
            right: 0;
            margin: auto 50px;
        }
    }

    .interactive {
        margin-bottom: 100px;
    }

    .between {
        justify-content: space-between;
        position: relative;
        margin: 0 auto 100px;
    }

    video.preview {
        mix-blend-mode: lighten;
        max-width: 1920px;
    }

    .carousel.hovered img {
        width: 100%;
    }

    .moon {
        margin-top: 300px;
    }

    .hovered {
        width: 50%;

        .abs {
            width: 50%;
            position: absolute;
            left: 0;
        }

        img {
            position: relative;
            width: 100%;
            margin: auto;
            padding: 0 10px;
        }
    }

    > .main {
        display: flex;
        width: 1920px;
        justify-content: flex-start;
    }

    img.wide {
        mix-blend-mode: lighten;
        width: 1920px;
        position: relative;
        left: 0;
        right: 0;
        margin: -280px auto auto;

        &.video {
            position: absolute;
            z-index: 1;
            mix-blend-mode: normal;
        }
    }
}
</style>
