<template>
    <div ref="videoComponent" class="video-component flex j-center a-center" :class="fadeIn?'create':''">
        <svg @click="startVideo" v-if="(full || !autoplay) && playButton" class="playButton t300" width="69" height="81"
             viewBox="0 0 69 81"
             fill="white" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M68.1891 40.5598C68.1916 41.6045 67.9237 42.632 67.4115 43.5425C66.8993 44.453 66.1602 45.2155 65.2661 45.7557L9.8534 79.6541C8.91916 80.2262 7.84914 80.5385 6.75385 80.5588C5.65855 80.579 4.57771 80.3066 3.62293 79.7695C2.67724 79.2407 1.88946 78.4696 1.34059 77.5355C0.791722 76.6013 0.50158 75.5378 0.5 74.4543V6.66529C0.50158 5.58182 0.791722 4.51833 1.34059 3.58417C1.88946 2.65001 2.67724 1.87891 3.62293 1.35016C4.57771 0.813063 5.65855 0.540586 6.75385 0.56087C7.84914 0.581153 8.91916 0.89346 9.8534 1.46554L65.2661 35.3639C66.1602 35.9042 66.8993 36.6666 67.4115 37.5771C67.9237 38.4876 68.1916 39.5151 68.1891 40.5598Z"/>
        </svg>
        <div class="previewArea" v-if="preview" ref="previewArea" :style="'min-height: ' + videoHeight">
            <div class="loadingSlot" ref="loadingSlot" v-if="loading">
                <slot name="loading">
                    <p>{{ $t("loading") }}</p>
                </slot>
            </div>
            <video ref="previewPlayer" :muted="muted" :loop="loop" class="preview t500"
                   :autoplay="autoplay" playsinline
                   :preload="preload?'metadata':'none'" :poster="getPoster()"
                   @ended="$emit('previewEnded', $event)">
                <slot name="videoSupport">
                    Your browser does not support the video tag.
                </slot>
                <source :src="getUrl(true)" type="video/mp4">
                <source :src="getUrl(true, 'webm')" type="video/webm">
            </video>
        </div>
        <div @click="startVideo">
            <slot/>
        </div>
        <div v-if="full" class="background t1000">
            <video ref="fullPlayer" preload="none" @click="fullScreen(false)" v-if="full" class="full t500" controls
                   @ended="fullScreen(false) && $emit('fullEnded', $event)" :key="localizedFull?$i18n.locale:''">
                <slot name="videoSupport">
                    Your browser does not support the video tag.
                </slot>
                <source :src="getUrl(false)" type="video/mp4">
                <source :src="getUrl(false, 'webm')" type="video/webm">
            </video>
        </div>
    </div>
</template>


<script>

export default {
    name: 'video-component',
    data() {
        return {
            fullscreen: false,
        }
    },
    props: {
        preview: {
            type: String,
            required: false,
        },
        full: {
            type: String,
        },
        fadeIn: {
            type: Boolean,
            default: true,
            required: false,
        },
        autoplay: {
            type: Boolean,
            default: true,
        },
        muted: {
            type: Boolean,
            default: true,
        },
        loop: {
            type: Boolean,
            default: true,
        },
        playButton: {
            type: Boolean,
            default: true,
        },
        localizedPreview: {
            type: Boolean,
            default: false,
        },
        localizedFull: {
            type: Boolean,
            default: false,
        },
        videoEnded: {
            type: Boolean,
            default: false,
        },
        poster: {
            type: String,
            required: false,
        },
        preload: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: true
        },
        videoHeight: {
            type: String,
            default: "100%"
        },
    },
    watch: {
        '$i18n.locale'() {
            if (this.localizedPreview)
                this.updatePreviewLocal();
        }
    },
    mounted() {
        document.addEventListener('fullscreenchange', this.handleFullScreenEvent);
        document.addEventListener('webkitfullscreenchange', this.handleFullScreenEvent);
        document.addEventListener('mozfullscreenchange', this.handleFullScreenEvent);
        document.addEventListener('MSFullscreenChange', this.handleFullScreenEvent);
        if (this.preview)
            this.getUrl(true);
        if (this.full)
            this.getUrl(false);
        this.onVisible(this.$refs.videoComponent, () => {
            if (this.$refs.previewPlayer?.paused)
                this.$refs.previewPlayer?.load();
        });
        if (this.$refs?.previewPlayer) {
            this.$refs.previewPlayer.onloadeddata = this.onLoaded;
        }
    },
    methods: {
        handleFullScreenEvent() {
            this.fullscreen = document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement;
            if (!this.fullscreen)
                this.stopVideo();
        },
        reload(el, binding) {
            if (binding.oldValue !== binding.value) {
                el.load();
            }
        },
        mobile() {
            return document.getElementById("mobile") !== null;
        },
        updatePreviewLocal() {
            let player = this.$refs.previewPlayer;
            let paused = player.paused;
            let currentTime = player.currentTime;
            player.pause();
            player.querySelector("source").src = this.getUrl(true);
            let poster = this.getPoster();
            if (poster)
                player.poster = poster;
            player.load();
            player.currentTime = currentTime;
            if (!paused)
                player.play();
        },
        apple() {
            return /iPhone|iPad|iPod/i.test(navigator.userAgent);
        },
        getPoster() {
            if (this.poster) {
                const loc = (this.poster && this.localizedPreview) || (!this.poster && this.localizedFull) ? "." + this.$i18n.locale : "";
                return process.env.VUE_APP_MEDIA_ROOT + this.poster + loc + '.jpg';
            }
            return undefined
        },
        onVisible(element, callback) {
            new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        callback(element);
                        observer.disconnect();
                    }
                });
            }).observe(element);
            if (!callback) return new Promise(r => callback = r);
        },
        getUrl(preview = true, type = "mp4") {
            const loc = (preview && this.localizedPreview) || (!preview && this.localizedFull) ? "." + this.$i18n.locale : "";
            const videoUrl = preview ? this.preview : this.full;
            return process.env.VUE_APP_MEDIA_ROOT + videoUrl + loc + '.' + type;
        },
        onLoaded() {
            const self = this;
            setTimeout(() => {
                self.$refs?.videoComponent?.classList.remove("create")
                if (self.loading && self.$refs.loadingSlot)
                    self.$refs.loadingSlot.style.display = "none";
            }, 1000);

        },
        startVideo() {
            let video = this.$refs.fullPlayer;
            if (!this.full || video == null) {
                if (!this.autoplay) {
                    if (this.$refs.previewPlayer.paused)
                        this.$refs.previewPlayer.play();
                    else
                        this.$refs.previewPlayer.pause();
                }
                return
            }
            if (this.apple()) {
                video.play();
            } else {
                window.scrollTo({
                    top: 0,
                });
                this.fullScreen();
                video.classList.add("active");
                setTimeout(function () {
                    video.play();
                }, 1000);
                document.querySelector("body").classList.add("videoFullscreen");
            }
        },
        stopVideo() {
            let video = this.$refs.fullPlayer;
            if (!this.full || video == null)
                return
            if (this.apple()) {
                video.load();
            } else {
                this.fullScreen(false);
                video.classList.remove("active");
                video.load();
                document.querySelector("body").classList.remove("videoFullscreen");
            }
        },
        fullScreen(enable = true) {
            if (!this.fullscreen && enable) {
                const video = this.$refs.fullPlayer;
                if (typeof (video.webkitEnterFullscreen) != "undefined") {
                    // This is for Android Stock.
                    video.webkitEnterFullscreen();
                } else if (typeof (video.webkitRequestFullscreen) != "undefined") {
                    // This is for Chrome.
                    video.webkitRequestFullscreen();
                } else if (typeof (video.mozRequestFullScreen) != "undefined") {
                    video.mozRequestFullScreen();
                }
            } else if (this.fullscreen) {
                const video = this.$refs.fullPlayer;
                if (typeof (video.webkitEnterFullscreen) != "undefined") {
                    // This is for Android Stock.
                    video.webkitEnterFullscreen();
                } else if (typeof (video.webkitRequestFullscreen) != "undefined") {
                    // This is for Chrome.
                    video.webkitRequestFullscreen();
                } else if (typeof (video.mozRequestFullScreen) != "undefined") {
                    video.mozRequestFullScreen();
                }

                // Exit full screen mode
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">

.video-component {
    .previewArea {
        display: grid;

        .loadingSlot {
            margin: auto;
            animation: fading 1s ease infinite;
        }

        > * {
            grid-area: 1/1/2/2;
        }


        @keyframes fading {
            0% {
                opacity: 0.3;
            }
            60% {
                opacity: 1;
            }
            100% {
                opacity: 0.3;
            }
        }
    }

    &.create video {
        opacity: 0;
    }

    video.preview {
        max-width: 100vw;
        width: 100%;
    }

    video {
        opacity: 1;
    }

    .background {
        width: 100%;
        height: 100%;
        position: fixed;
        background: black;
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -100;
        display: none;

        &:has(video.full.active), video.full.active {
            opacity: 1;
            z-index: 10000;
            display: unset;
        }

        video.full {
            margin: auto;
            top: 0;
            bottom: 0;
            position: fixed;
            left: 0;
            right: 0;
            width: 100%;
            max-height: 100vh;
            z-index: -100;

            &.active {
                z-index: 9999;
            }
        }
    }

    &:hover .playButton {
        fill-opacity: 0.75;
    }

    .playButton {
        position: absolute;
        z-index: 1;
        fill-opacity: 0;

        &:active {
            fill: var(--color-accent);
        }
    }
}
</style>
