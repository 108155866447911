<template>
    <abyss-vue/>
</template>
<script>

import AbyssVue from "@/views/desktop/shortfilms/Abyss.vue";

export default {
    name: 'abyss-mobile-vue',
    components: {AbyssVue},
}
</script>

<style lang="scss">


#mobile #abyss {
}

</style>