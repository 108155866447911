<template>
    <div id="menu">
        <div class="menu-item" v-for="(m, link) in menu" :key="`menu${link}`">
            <router-link :to="'/' + link" class="t300 hover">
                {{ $t(m + ".name") }}
            </router-link>
            <div class="menu-hover-line t300"/>
        </div>
    </div>
</template>

<script>
import configs from "@/globals/configs.json";

export default {
    name: 'menu-component',
    data() {
        return {
            active: "workTogether",
            menu: configs.menu
        }
    },
}
</script>

<style lang="scss">


#menu {
    position: sticky;
    display: flex;
    z-index: 10;
    margin: 0 10vw 0 auto;
    flex-direction: row;
    justify-content: flex-end;
    height: 10vh;

    .menu-item {
        white-space: nowrap;
        margin: 5vh 0 0 110px;

        > .menu-hover-line {
            opacity: 0;
            filter: blur(6px);
            border: 3px solid var(--color-accent);
            width: calc(100% - 5px);
            margin: auto;
            top: -7px;
            position: relative;
        }

        > .router-link-active {
            color: var(--color-accent);

        }

        > a:hover {
            color: var(--color-accent);

            + .menu-hover-line {
                opacity: 1;
            }
        }


    }
}
</style>