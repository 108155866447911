<template>
    <div ref="interactive" class="interactive flex t500">
        <video-component :preview="video" :loop="false" @previewEnded="handleVideoEnded" v-if="video"/>

        <template v-for="(img, key, i) in data" :key="key">
            <img-component class="control-img" :data-ref="key" :class="{'hidden' : !(i===0 && interaction), 'active' : (i===0 && interaction)}"
                 :src="img" />
        </template>

        <div class="control t500 flex" v-if="interaction">
            <div ref="lines" class="lines t500">
                <div class="line-left t500"></div>
                <div class="line-middle t500"></div>
                <div class="line-right t500"></div>
            </div>
            <div :data-ref="key" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" class="t300 control-element"
                 v-for="(img, key) in data" :key="'interactive' + img + interaction + $i18n.locale">
                {{ $t(key) }}
            </div>
        </div>

        <div class="playButton" @click="handlePlayAgain">
            {{ interaction ? $t("button.play") : $t("button.skip") }}
        </div>
    </div>
</template>


<script>

import VideoComponent from "@/components/dekstop/Video.vue";
import ImgComponent from "@/components/dekstop/Img.vue";

export default {
    name: 'interactive-component',
    components: {ImgComponent, VideoComponent},
    data() {
        return {
            currentIndex: 0,
            intervalId: undefined,
            interaction: false,
            startValue: 150,
            endValue: 850,
        }
    },
    props: {
        video: {
            type: String,
            required: false,
        },
        data: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        if (!this.video) {
            this.handleVideoEnded();
        }
    },
    methods: {
        handleVideoEnded() {
            this.interaction = true;
            setTimeout(() => {
                this.$refs.interactive.classList.add("white");
            }, 100)
            this.handleMouseLeave();
        },
        handleMouseEnter(event) {
            const ref = event.currentTarget.dataset['ref'];
            Array.from(event.currentTarget.closest(".interactive").getElementsByClassName("control-img")).forEach((element, i) => {
                if (element.dataset['ref'] === ref) {
                    element.classList.add("active");
                    this.handleLines(i);
                } else element.classList.remove("active");
            });
        },
        handleMouseLeave() {
            Array.from(this.$refs.interactive.getElementsByClassName("control-img")).forEach((element, i) => {
                if (i === 0)
                    element.classList.add("active")
                else element.classList.remove("active")
            });
            this.handleLines();
        },
        handleLines(index = 0) {
            this.$refs.lines?.querySelector(".line-left")?.style.setProperty("width", this.startValue + index * 200 + "px");
            this.$refs.lines?.querySelector(".line-right")?.style.setProperty("width", this.endValue - index * 200 + "px");
        },
        handlePlayAgain() {
            if (this.interaction) {
                this.interaction = false;
                const i = this.$refs.interactive;
                i.classList.remove("white");
                i.querySelector("video.preview")?.play();
            } else {
                const i = this.$refs.interactive;
                i.querySelector("video.preview")?.load();
                i.querySelector("video.preview")?.pause();
                this.handleVideoEnded();
            }
        }
    }
}
</script>

<style lang="scss">


.interactive {
    position: relative;
    height: 1080px;
    width: 1920px;

    &.white {
        background: white;

        .control-img {
            transition: 500ms filter ease-in-out, 500ms opacity ease-in-out;
        }

        > .video-component.hide {
            opacity: 0;
        }
    }

    .control-img {

        &:not(.hidden):not(.active) {
            filter: blur(5px);
            opacity: 0.3;
        }

        &.hidden {
            opacity: 0;
        }

        &.active {
            opacity: 1;
        }
    }

    .control {
        z-index: 15;
        bottom: 100px;
        height: fit-content;
        margin: auto auto 0 100px;
        left: 0;
        position: relative;
        width: fit-content;

        .control-element {
            width: 200px;
            text-align: center;
            cursor: pointer;
        }
    }

    .playButton {
        z-index: 15;
        color: var(--color-secondary);
        border: 1px solid var(--color-secondary);
        width: 220px;
        height: 29px;
        border-radius: 16px;
        text-align: center;
        white-space: nowrap;
        position: absolute;
        left: 90px;
        bottom: 50px;
        cursor: pointer;
    }

    > * {
        position: absolute;
        width: 100%;
        height: auto;
    }

    .video-component {
        transition: none;
    }

    video.preview {
        width: 100%;
        height: auto;
    }

    .lines {
        position: absolute;
        display: flex;
        bottom: 20px;
        left: -60px;

        .line-left, .line-middle, .line-right {
            border: 1px solid var(--color-secondary);
            border-radius: 2px;
            height: 0;
            margin: 0 1.5px;
        }

        .line-left {
            position: relative;
            width: 150px;
            border-image: linear-gradient(to left, var(--color-secondary), transparent) 2;
        }

        .line-middle {
            width: 20px;
            position: relative;
            height: 20px;
            rotate: 315deg;
            border-width: 2px;
            border-left: none;
            border-bottom: none;
            border-radius: 0;
            bottom: 10px;

            &.click {
                border-width: 20px;
            }
        }

        .line-right {
            position: relative;
            width: 850px;
            border-image: linear-gradient(to right, var(--color-secondary), transparent) 2;
        }
    }
}

</style>
