<template>
    <div id="impress">
        <h1>{{ $t("showVideoMobile.name") }}</h1>
        <p class="max-width" v-html="$t('showVideoMobile.data')"/>
    </div>
</template>

<script>

export default {
    name: 'show-video-mobile-vue',
}
</script>

<style lang="scss">
#ShowVideoMobile {
}
</style>