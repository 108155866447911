<template>
    <div class="topArrow top" ref="arrow">
        <a @click="scroll" class="arrow"/>
    </div>
</template>
<script>

export default {
    name: 'top-component',
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    props: {},
    methods: {
        scroll() {
            let app = document.getElementById("app");
            if (app) {
                app.scrollIntoView({behavior: "smooth"});
            }
        },
        handleScroll(event) {
            if (window.scrollY > 500) {
                this.$refs?.arrow?.classList?.remove("top");
            } else {
                this.$refs?.arrow?.classList?.add("top");
            }
        }
    }
}
</script>

<style scoped lang="scss">
.topArrow {
    padding-top: 50px;
    max-width: 30px;
    position: sticky;
    bottom: 20px;
    left: 0;
    right: 0;
    z-index: 1000;

    &::after{
        content: "";
        box-shadow: 18px -20px 50px 26px var(--color-background);
        z-index: -31;
        position: relative;
    }

    &.top{
        margin: 130vh auto -130vh !important;
    }

    .arrow {
        cursor: pointer;
        border: solid var(--color-accent);
        border-width: 0 5px 5px 0;
        display: block;
        padding: 15px;
        transform: rotate(-45deg);
        border-radius: 4px;
        -webkit-transform: rotate(-135deg);
    }
}
</style>
