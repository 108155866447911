<template>
    <div class="scroll-component">
        <div class="scroll-elements" ref="scrollElements">
            <div class="videos"
                 @touchstart="handleMouseDown" @touchend="handleMouseUp" @touchmove="handleMouseMove"
                 @mousedown="handleMouseDown" @mouseup="handleMouseUp" @mousemove="handleMouseMove"
                 @mouseleave="handleMouseUp">
                <video-component @previewEnded="handlePreviewEnded" @click="handleClick" v-for="(poster, video) in videos" :key="video"
                                 :preview="video" :autoplay="false" :muted="false" :poster="poster?video:undefined" :loading="false" :play-button="false"
                />
            </div>
        </div>
        <text-component font-size="4dvmax" stroke-width="0.9dvmax" height="5dvmax">
            <template #normal>
                {{ firstWord($t(title)) }}
            </template>
            <template #accent>
                {{ restText($t(title)) }}
            </template>
        </text-component>
    </div>
</template>

<script>

import VideoComponent from "@/components/dekstop/Video.vue";
import TextComponent from "@/components/dekstop/Text.vue";

export default {
    name: 'scroll-component',
    components: {TextComponent, VideoComponent},

    data() {
        return {
            isMouseDown: true,
            isMouseMoved: false,
            startX: 0,
            scrollLeft: 0,
            lastX: undefined,
            lastY: undefined
        }
    },
    props: {
        videos: {
            required: true,
        },
        title: {
            type: String,
            default: "",
        },
    },
    methods: {
        handlePreviewEnded(event) {
            event.currentTarget.querySelector("video.preview").currentTime = 0;
        },
        handleClick(event) {
            if (!this.isMouseMoved) {
                let video = event.currentTarget.querySelector("video.preview");
                const isVideoPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
                if (isVideoPlaying) {
                    video.load();
                } else {
                    video.play();
                }
            }
        },
        firstWord(text) {
            return text.trim().split(' ').shift();
        },
        restText(text) {
            const words = text.trim().split(' ');
            words.shift();
            return " " + words.join(' ');
        },
        handleMouseDown(event) {
            this.isMouseMoved = false;
            this.isMouseDown = true;
            var pageX = 0;
            if (event?.type === "touchstart") {
                let touch = event?.touches[0];
                pageX = touch.pageX;

            } else {
                pageX = event.pageX;
            }
            this.startX = pageX - event.currentTarget.closest(".scroll-elements").offsetLeft;
        },
        handleMouseUp(event) {
            this.isMouseDown = false;
            this.scrollLeft = event.currentTarget.closest(".scroll-elements").scrollLeft;
        },
        handleMouseMove(event) {
            if (!this.isMouseDown) return;
            this.isMouseMoved = true;
            let pageX = 0;
            let deltaX = 0;
            let deltaY = 0;
            if (event?.type === "touchmove") {
                let touch = event?.touches[0];
                pageX = touch.pageX;
                deltaX = this.lastX ? this.lastX - touch.pageX : 0;
                deltaY = this.lastY ? this.lastY - touch.pageY : 0;

            } else {
                pageX = event.pageX;
                deltaX = this.lastX ? this.lastX - event.pageX : 0;
                deltaY = this.lastY ? this.lastY - event.pageY : 0;
            }
            if (Math.abs(deltaX) < Math.abs(deltaY)) {
                event.preventDefault();
            } else {
                const x = pageX - event.currentTarget.closest(".scroll-elements").offsetLeft;
                const walk = (x - this.startX) * 2; // Adjust the scrolling speed here
                event.currentTarget.closest(".scroll-elements").scrollLeft = this.scrollLeft - walk;
            }
        },
    }
}
</script>

<style lang="scss">
.scroll-component {
    width: 85vw;
    margin: 50px auto 50px 15vw;

    .scroll-elements {
        overflow-x: hidden;
        white-space: nowrap;
        cursor: pointer;

        .video-component {
            display: inline-block;
            margin-right: 26px;
            transition: none;
            max-width: 65vw;

            video.preview {
                border-radius: 20px;
            }
        }
    }

    .text-component {
        margin: -3dvmax 0 0 25px;
        text-transform: uppercase;
    }
}
</style>