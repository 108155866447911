<template>
    <ampiria-vue/>
    <top-component/>
</template>

<script>

import AmpiriaVue from "@/views/desktop/works/Ampiria.vue";
import TopComponent from "@/components/dekstop/Top.vue";
import VideoComponent from "@/components/dekstop/Video.vue";

export default {
    name: 'ampiria-mobile-vue',
    components: {VideoComponent, TopComponent, AmpiriaVue},
}
</script>

<style lang="scss">


#mobile #ampiria {

    .img.page {
        max-width: 100%;
    }

    .main .carousel {
        margin: 100px auto;
    }

    .switch {
        max-width: 90vw;

        .control {
            max-width: 100%;
        }
    }

    img.page {
        max-width: 100%;
    }
}
</style>
