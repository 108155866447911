<template>
    <div id="rawMind" class="dark-background">
        <div class="main">
            <div class="left-side">
                <h2>{{ $t("works.rawMindPictures.name") }}</h2>
                <p class="description" v-html="$t('works.rawMindPictures.description')"/>
            </div>
            <video-component :preview="main" class="moon" v-if="!mobile()"/>
        </div>
        <div class="gallery">
            <div class="galleryRow flex" v-for="(cols, row) in gallery" :key="'row' + row">
                <div @mouseenter="handleEnter" @mouseleave="handleLeave" @click="handleClick" class="hovered t300" :title="$t('clickMe')"
                     v-for="(videos, key) in cols"
                     :key="'col' + key">
                    <video-component :play-button="false" :autoplay="mobile()" :preview="videos[0]" :alt="key + '1'" class="normal t500"/>
                    <video-component :play-button="false" :autoplay="false" :preview="videos[1]" :alt="key + '2'" class="makingOf hover abs hide"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import configs from "@/globals/configs.json";
import VideoComponent from "@/components/dekstop/Video.vue";

export default {
    name: 'rawmind-vue',
    components: {VideoComponent},
    data() {
        return {
            main: configs.videos.rawMind.main,
            gallery: configs.videos.rawMind.gallery,
            unloadTimer: undefined
        }
    },
    methods: {
        handleClick(event) {
            let hovered = event.currentTarget;
            const videoContainer = hovered.querySelector(".hover");
            hovered.classList.toggle("active")
            if (videoContainer.classList.contains("hide")) {
                clearTimeout(this.unloadTimer)
                videoContainer.classList.remove("hide");
                videoContainer.querySelector("video.preview").currentTime = 0;
                videoContainer.querySelector("video.preview")?.play();
                hovered.querySelector(".video-component.normal").classList.add("blur");
                this.handleLeave(event);
            } else {
                videoContainer.classList.add("hide");
                this.unloadTimer = setTimeout(() => {
                    videoContainer.querySelector("video.preview")?.load();
                }, 1000)
                hovered.querySelector(".video-component.normal").classList.remove("blur");
                this.handleEnter(event);
            }
        },
        handleEnter(event) {
            if (this.mobile())
                return;
            const videoContainerHover = event.currentTarget.querySelector(".hover");
            if (videoContainerHover.classList.contains("hide")) {
                const videoContainer = event.currentTarget.querySelector(".normal");
                videoContainer.querySelector("video.preview")?.play();
            }
        },
        handleLeave(event) {
            if (this.mobile())
                return;
            const videoContainer = event.currentTarget.querySelector(".normal");
            videoContainer.querySelector("video.preview")?.pause();
        },
        mobile() {
            return document.getElementById("mobile") !== null;
        },
    }
}
</script>

<style lang="scss">
#rawMind {
    .description {
        width: 509px;
        font-weight: 400;
    }

    .videoDescription {
        width: 642px;
        margin: 253px 0 45px 0;
        font-weight: 400;
    }

    > .main {
        width: 1920px;
        min-height: 500px;
        margin: auto;

        .left-side {
            width: 500px;
            max-width: 500px;
            margin-left: 30%;
        }

        .video-component {
            margin: -410px 0 0 40%;
            position: relative;
            width: 1180px;

            video.preview {
                mix-blend-mode: screen;
                width: 100%;
            }
        }
    }

    .gallery {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto 111px auto;
        width: 90vw;

        .galleryRow {
            width: 80vw;
        }

        .hovered {
            margin: 2vh 1vw;
            position: relative;
            width: 50%;

            &:not(.active):hover {
                box-shadow: 0 0 20px 1px var(--color-accent);
            }

            &:only-child {
                width: 100%;
            }

            .blur > video.preview {
                filter: blur(10px);
            }

            .video-component {
                height: auto;
                cursor: pointer;

                video.preview {
                }

                &.hover {
                    top: 0;
                    bottom: 0;
                    width: 100%;

                    &:not(.hide) > video.preview {
                        width: 98%;
                        margin: auto;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                }

                &.hide {
                    opacity: 0;
                }
            }
        }
    }

}
</style>
