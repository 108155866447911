<template>
    <div id="abyss" class="flex column dark-background">
        <VideoComponent v-for="(s, v, i) in videos" :key="`video${v}`" :preview="'movies/abyss/'+ v"
                        :full="i === 0 ? 'movies/abyss/' : ''" :style="s" :localized-preview="true"/>
    </div>
</template>
<script>
import configs from "@/globals/configs.json";
import {defineAsyncComponent} from "vue";

export default {
    name: 'abyss-vue',
    components: {
        VideoComponent: defineAsyncComponent(() =>
            import('@/components/dekstop/Video.vue')
        )
    },
    data() {
        return {
            videos: configs.videos.abyss
        }
    },
}
</script>

<style lang="scss">


#abyss {
    width: 80%;
    margin: 100px auto;

    video.preview {
        mix-blend-mode: screen;
        min-width: 100px;
        width: 100%;
    }
}

</style>