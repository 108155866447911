<template>
    <about-vue/>
</template>

<script>
import AboutVue from "@/views/desktop/About.vue";

export default {
    name: 'about-mobile-vue',
    components: {AboutVue},
}
</script>

<style lang="scss">
#mobile #about {

}
</style>