<template>
    <div>About</div>
</template>

<script>
export default {
    name: 'about-vue',
}
</script>

<style lang="scss">
#about {

}
</style>