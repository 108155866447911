<template>
    <div id="showVideo">
        <video-component :preview="context + '/' + id" :full="context + '/' + id" :autoplay="false"/>
    </div>
</template>

<script>

import VideoComponent from "@/components/dekstop/Video.vue";

export default {
    name: 'show-video-vue',
    components: {VideoComponent},
    data() {
        return {
            context: this.$route.params.context,
            id: this.$route.params.id
        }
    }
}
</script>

<style lang="scss">
#showVideo {
    max-width: 1000px;
    margin: 0 0 200px 0;
    video.preview{
        border-radius: 50px;
    }
}
</style>