<template>
    <rawmind-vue/>
    <top-component/>
</template>

<script>

import RawmindVue from "@/views/desktop/works/RawMind.vue";
import TopComponent from "@/components/dekstop/Top.vue";
import AmpiriaVue from "@/views/desktop/works/Ampiria.vue";

export default {
    name: 'rawmind-mobile-vue',
    components: {AmpiriaVue, TopComponent, RawmindVue},
}
</script>

<style lang="scss">
#mobile #rawMind {
    .main {
        display: block;
        width: 100%;
        margin: auto;

        .left {
            margin: auto;
            min-width: 30%;
            width: 100%;
            max-width: 500px;

            .description {
                width: unset;
            }
        }
    }

    .gallery {
        .hovered {
            width: 100%;
            margin: 5vh auto;

            video {
            }
        }

        .galleryRow {
            flex-direction: column;
            width: 100%;
        }
    }
}
</style>
