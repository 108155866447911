<template>
    <div id="data">
        <h1>{{ $t("data.name") }}</h1>
        <p class="max-width" v-html="$t('data.data')"/>
    </div>
</template>

<script>

export default {
    name: 'data-vue',
}
</script>

<style lang="scss">
#data {
    max-width: 1000px;
    margin: auto;

    h1 {
        margin: 0 0 154px 0;
        font-size: 80px;
        font-weight: 300;
        line-height: 96px;
    }
    h2{
        margin: 0;
        font-size: 30px;
    }

    p {
        margin: 0 0 300px 0;
    }
}
</style>