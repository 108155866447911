<template>
    <div id="background">
        <div class="skills">
            <div class="skillCol" v-for="(skillRow, row) in skills" :key="row">
                <div class="skillContainer" v-for="skill in skillRow" :key="skill" @mouseenter="handleEnter"
                     @mouseleave="handleLeave" :data-label="skill">
                    <video-component @previewEnded="handleEnded" class="start active" :loading="false"
                                     :preview="skill" :autoplay="false" :play-button="false"
                                     :loop="false"/>
                    <video-component @previewEnded="handleEnded" class="end" :preview="skill + '_Ende'" :autoplay="false" :loading="false" :play-button="false"
                                     :loop="false"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VideoComponent from "@/components/dekstop/Video.vue";
import configs from "@/globals/configs.json";

export default {
    name: 'background-component',
    components: {VideoComponent},
    data() {
        return {
            skills: configs.videos.background,
            timeouts: {}
        }
    },
    methods: {
        handleEnded(event) {
            let start = event.target.closest(".skillContainer").querySelector(".video-component.start");
            let end = event.target.closest(".skillContainer").querySelector(".video-component.end");
            let startVideo = start.querySelector(".preview");
            let endVideo = end.querySelector(".preview")

            startVideo.currentTime = 0;
            endVideo.currentTime = 0;
            if (start.classList.contains("active")) {
                start.classList.remove("active");
                end.classList.add("active");
            } else {
                start.classList.add("active");
                end.classList.remove("active");
            }
        },
        handleEnter(event) {
            let start = event.target.querySelector(".video-component.start");
            let startVideo = start.querySelector(".preview")
            if (start.classList.contains("active")) {
                startVideo.play();
            }
        },
        handleLeave(event) {
            let label = event.target.dataset.label;
            clearTimeout(this.timeouts[label]);
            this.timeouts[label] = setTimeout(function () {
                let end = event.target.querySelector(".video-component.end");
                let endVideo = end.querySelector(".preview")

                if (end.classList.contains("active")) {
                    endVideo.play();
                }
            }, 15000);
        }
    },
}
</script>

<style lang="scss">

#background {
    margin: 50px 0 0 auto;
    position: absolute;

    .skills {
        display: flex;
        justify-content: center;

        .skillCol {
            margin: 0 -25px;

            .skillContainer {
                position: relative;

                .video-component {
                    max-width: 280px;
                    top: 0;
                    opacity: 0;
                    transition: none;

                    &.active {
                        opacity: 1;
                        position: absolute;

                        video {
                            mix-blend-mode: lighten;
                        }
                    }
                }
            }
        }
    }
}

</style>