<template>
    <raumedic-vue/>
    <top-component/>
</template>

<script>

import RaumedicVue from "@/views/desktop/works/Raumedic.vue";
import TopComponent from "@/components/dekstop/Top.vue";
import AmpiriaVue from "@/views/desktop/works/Ampiria.vue";

export default {
    name: 'raumedic-mobile-vue',
    components: {AmpiriaVue, TopComponent, RaumedicVue},
}
</script>

<style lang="scss">
#mobile #raumedic {

    img.main {
        margin: auto;
        width: 100%;
    }
    .imageRow{
        flex-direction: column;
    }
}
</style>
