<template>
    <div id="sketch" class="dark-background">
        <img-component src="movies/lastSketch/poster.jpg" />
        <div class="comingSoon">
            {{$t("comingSoon")}}
        </div>
    </div>
</template>
<script>
import configs from "@/globals/configs.json";
import {defineAsyncComponent} from "vue";
import ImgComponent from "@/components/dekstop/Img.vue";

export default {
    name: 'sketch-vue',
    components: {ImgComponent},
}
</script>

<style lang="scss">
#sketch {
    .comingSoon{
        margin: 0 auto 20vh;
        display: block;
        width: fit-content;
        max-width: 80%;
        color: var(--color-text);
    }

    img{
        margin: 0 auto 5vh;
        display: block;
        width: 800px;
        max-width: 80%;
    }
}

</style>