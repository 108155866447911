<template>
    <div class="contact-form b400">
        <h1 class="b300">{{ $t("contact.name") }}</h1>
        <p class="max-width" v-html="$t('project')"/>
        <p class="max-width" v-html="$t('contactInformation')"/>
    </div>
</template>

<script>
// import * as emailjs from "emailjs-com";
import configs from "@/globals/configs.json";

export default {

    name: 'contact-vue',
    data() {
        return {
            form: {
                email: '',
                name: '',
                subject: '',
                message: ''
            },
            contactMail: configs.mail.receiver,
            show: true
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            // const templateParams = {
            //     to_email: this.contactMail,
            //     subject: this.form.subject,
            //     content: this.form.message,
            //     name: this.form.name,
            //     date: new Date().toDateString(),
            // };

            // emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', templateParams, 'YOUR_USER_ID')
            //     .then(response => {
            //     })
            //     .catch(error => {
            //         console.error('Failed to send email:', error);
            //     });
        }
    }
}
</script>

<style lang="scss">


.contact-form {
    margin: 0 auto 300px;
    max-width: 1000px;
    width: 1920px;

    color: var(--color-text);

    h1 {
        //margin-top: 260px;
        //margin-bottom: 52px;
        margin: 100px 0 52px 0;
        color: var(--color-accent);
    }

    form {
        font-size: 20px;
        margin-top: 73px;

        button.submit {
            height: 67px;
            width: 280px;
            border-radius: 50px;
            background: var(--color-text);
            margin-top: 61px;
            margin-bottom: 298px;
            right: 70px;
            float: right;
            position: relative;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            color: var(--color-background);
        }

        .form-row {
            display: flex;
        }

        .input-field {
            width: 100%;
            margin-top: 38px;

            label {
                margin-bottom: 10px;
                white-space: nowrap;
            }

            input, textarea {
                border-radius: 0;
                height: 67px;
                font-size: 20px;
                resize: none;
                background: var(--color-text);
            }

            .large {
                height: 308px;
            }

            + .input-field {
                margin-left: 35px;
            }
        }
    }

    p {
        font-size: 20px;
        line-height: 24px;
        height: 106px;

        &.max-width {
            max-width: 436px;

            a {
                font-weight: bolder;
            }
        }
    }
}

</style>