<template>
    <div id="home" :class="hide">
        <div class="text">
            <div class="accent">
                <h2 class="first-line b300 t1000">
                    {{ $t("hello") }}
                </h2>
                <h2 class="second-line b300 t1000">
                    {{ $t("iam") }}
                </h2>
                <carousel-component :interval="3000">
                    <h1 class="third-line t600 t1000" v-for="(header, index) in headers" :key="index">
                        {{ $t(header) }}
                    </h1>
                </carousel-component>
            </div>
            <p class="t1000 description">{{ $t("welcome") }}</p>
        </div>
        <img-component class="t300 main" src="website/Edgar.png" alt="edgar"/>
    </div>
</template>

<script>


import configs from "@/globals/configs.json";
import CarouselComponent from "@/components/dekstop/Carousel.vue";
import ShowreelsMobileVue from "@/views/mobile/start/ShowreelsMobile.vue";
import ImgComponent from "@/components/dekstop/Img.vue";

export default {
    name: 'start-mobile-vue',
    components: {ImgComponent, ShowreelsMobileVue, CarouselComponent},
    data() {
        return {
            hide: "hide",
            headers: configs.navigation.headers,
        }
    },
    mounted() {
        const self = this;
        setTimeout(function () {
            self.hide = "";
        }, 100);
    },
}
</script>

<style lang="scss">

#mobile #home {
    padding-left: 0;
    width: unset;

    .accent {
        padding-left: 20px;
        margin: auto;
        width: 80vw;
    }

    &.hide {
        img {
            margin-top: 100px;
        }
    }

    .text {
        margin: auto;
        width: 50%;
        min-width: 300px;
    }

    p.description {
        transition: unset;
    }

    img {
        transition: opacity 1s ease-in-out, margin 1.1s ease-out;
        height: auto;
        max-width: 1000px;
        position: inherit;
        top: unset;
        right: unset;
        margin: auto;
        display: block;
    }

    @media (max-width: 1000px) {
        img {
            max-width: 90vw;
        }
    }

    @media (max-width: 920px) {
        .accent .third-line {
            font-size: 7vw;
        }
    }

    @media (max-width: 650px) {
        p.description {
            width: 70vw;
        }
    }
}
</style>