import {createRouter, createWebHistory} from "vue-router";
import About from "@/views/desktop/About.vue";
import Contact from "@/views/desktop/Contact.vue";
import Abyss from "@/views/desktop/shortfilms/Abyss.vue";
import Ampiria from "@/views/desktop/works/Ampiria.vue";
import Astrait from "@/views/desktop/works/Astrait.vue";
import Raumedic from "@/views/desktop/works/Raumedic.vue";
import RawMind from "@/views/desktop/works/RawMind.vue";
import Pop from "@/views/desktop/works/Pop.vue";
import Impress from "@/views/desktop/Imrpress.vue";
import Data from "@/views/desktop/Data.vue";
import Start from "@/views/desktop/start/Start.vue";
import StartMobile from "@/views/mobile/start/StartMobile.vue";
import AboutMobile from "@/views/mobile/AboutMobile.vue";
import ContactMobile from "@/views/mobile/ContactMobile.vue";
import DataMobile from "@/views/mobile/DataMobile.vue";
import PopMobile from "@/views/mobile/works/PopMobile.vue";
import RawMindMobile from "@/views/mobile/works/RawMindMobile.vue";
import RaumedicMobile from "@/views/mobile/works/RaumedicMobile.vue";
import AstraitMobile from "@/views/mobile/works/AstraitMobile.vue";
import AmpiriaMobile from "@/views/mobile/works/AmpiriaMobile.vue";
import AbyssMobile from "@/views/mobile/shortfilms/AbyssMobile.vue";
import ImrpressMobile from "@/views/mobile/ImrpressMobile.vue";
import ShowVideo from "@/views/desktop/ShowVideo.vue";
import ShowVideoMobile from "@/views/mobile/ShowVideoMobile.vue";
import Sketch from "@/views/desktop/shortfilms/Sketch.vue";
import SubMenuMobile from "@/views/mobile/SubMenuMobile.vue";
import SketchMobile from "@/views/mobile/shortfilms/SketchMobile.vue";
import ShowreelsMobile from "@/views/mobile/start/ShowreelsMobile.vue";

const routes = [
    {
        path: "/",
        components: {
            default: Start, mobile: StartMobile
        },
        meta: {id: 'start', active: "huebertDesign"},
        alias: ['/start', "/huebertDesign"],
    },
    {
        path: "/showreels",
        components: {
            default: Start, mobile: ShowreelsMobile
        },
        meta: {id: 'start', active: "showreels"},
    },
    {
        path: "/about",
        components: {
            default: About, mobile: AboutMobile
        },
        meta: {id: 'about', active: "about"},
    },
    {
        path: "/contact",
        components: {
            default: Contact, mobile: ContactMobile
        },
        meta: {id: 'contact', active: "contact"},
    },
    {
        path: "/shortFilms",
        redirect: {path: "/shortFilms/abyss"},
        children: [
            {
                path: "abyss",
                components: {
                    default: Abyss, subMenu: SubMenuMobile, mobile: AbyssMobile
                },
                meta: {id: 'shortFilms', active: "abyss"},
            },
            {
                path: "sketch",
                components: {
                    default: Sketch, subMenu: SubMenuMobile, mobile: SketchMobile
                },
                meta: {id: 'shortFilms', active: "sketch"},
            },
        ],
    },
    {
        path: "/works",
        redirect: {path: "/works/ampiria"},
        children: [
            {
                path: "ampiria",
                components: {
                    default: Ampiria, subMenu: SubMenuMobile, mobile: AmpiriaMobile
                },
                meta: {id: 'works', active: "ampiria"},
            },
            {
                path: "astrait",
                components: {
                    default: Astrait, subMenu: SubMenuMobile, mobile: AstraitMobile
                },
                meta: {id: 'works', active: "astrait", navigationOut: true},
            },
            {
                path: "raumedic",
                components: {
                    default: Raumedic, subMenu: SubMenuMobile, mobile: RaumedicMobile
                },
                meta: {id: 'works', active: "raumedic", navigationOut: true},
            },
            {
                path: "rawMindPictures",
                components: {
                    default: RawMind, subMenu: SubMenuMobile, mobile: RawMindMobile
                },
                meta: {id: 'works', active: "rawMindPictures"},
            },
            {
                path: "deutschePOP",
                components: {
                    default: Pop, subMenu: SubMenuMobile, mobile: PopMobile
                },
                meta: {id: 'works', active: "deutschePOP"},
            },
        ],
    },
    {
        path: "/impress",
        components: {
            default: Impress, mobile: ImrpressMobile
        },
        meta: {id: 'impress', active: "impress"},
    },
    {
        path: "/data",
        components: {
            default: Data, mobile: DataMobile
        },
        meta: {id: 'data', active: "data"},
    },
    {
        path: "/showVideo/:context/:id",
        components: {
            default: ShowVideo, mobile: ShowVideoMobile
        },
        meta: {id: 'showVideo', active: "showVideo"},
    }
];

export const routing = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({left: 0, top: 0})
            }, 300)
        })
    }
});