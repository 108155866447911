<template>
    <astrait-vue/>
    <top-component/>
</template>

<script>

import AstraitVue from "@/views/desktop/works/Astrait.vue";
import TopComponent from "@/components/dekstop/Top.vue";
import AmpiriaVue from "@/views/desktop/works/Ampiria.vue";

export default {
    name: 'ampiria-mobile-vue',
    components: {AmpiriaVue, TopComponent, AstraitVue},
}
</script>

<style lang="scss">
#mobile #astrait {
    .main .rocket {
        display: none
    }

    .horizontal-line {
        margin: 120px auto 50px;
    }

    img.wide {
        width: 100%;
        margin: auto;
    }

    .wide.video {
        margin-top: 50vw;
    }

    .moon {
        margin-top: 100vw;
    }

    .between {
        flex-direction: column;

        .hovered {
            width: 100%;
            margin: auto auto 5vw;

            .abs {
                width: 100%;
            }

        }
    }

    .interactive {
        display: none;
    }
}
</style>
