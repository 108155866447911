<template>
    <impress-vue/>
</template>

<script>

import ImpressVue from "@/views/desktop/Imrpress.vue";

export default {
    name: 'impress-mobile-vue',
    components: {ImpressVue},
}
</script>

<style lang="scss">
#mobile #impress {
    h1 {
        margin-bottom: 25px;
    }

    p.max-width {
        max-width: 100%;
        margin-bottom: 25px;
    }
}
</style>