<template>
    <div class="text-component">
        <svg xmlns="http://www.w3.org/2000/svg" :height="height" width="100%">
            <g
                :fill="normal"
                :stroke="stroke"
                :stroke-width="strokeWidth"
                :font-family="fontFamily"
                :font-size="fontSize"
                :font-weight="fontWeight"
                :text-anchor="textAnchor"
                stroke-linejoin="miter">
                <text paint-order="stroke" text-anchor="start" dominant-baseline="text-before-edge">
                    <slot name="normal"/>
                    <tspan :fill="accent" dominant-baseline="text-before-edge">
                        <slot name="accent"/>
                    </tspan>
                </text>
            </g>
        </svg>
    </div>
</template>

<script>

export default {
    name: 'text-component',
    props: {
        accent: {
            type: String,
            default: "#FF4900",
        },
        stroke: {
            type: String,
            default: "#1A1A1A",
        },
        firstWord: {
            type: String,
        },
        normal: {
            type: String,
            default: "#fff",
        },
        strokeWidth: {
            type: String,
            default: "0",
        },
        fontWeight: {
            type: String,
            default: "700",
        },
        fontFamily: {
            type: String,
            default: "Poppins",
        },
        fontSize: {
            type: String,
            default: "36",
        },
        textAnchor: {
            type: String,
            default: "start",
        },
        height: {
            type: String,
            default: "70",
        },
    },
}
</script>

<style lang="scss">

.text-component {
    margin-bottom: 15px;

    tspan{
        margin: auto;
    }
}
</style>