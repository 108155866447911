<template>
    <div id="raumedic" class="light-theme">
        <div class="main">
            <div class="left-side">
                <h2>{{ $t("works.raumedic.name") }}</h2>
                <p class="description" v-html="$t('works.raumedic.description')"/>
            </div>
            <img-component class="main" :src="pictures.main" alt="main"/>
        </div>
        <div class="images">
            <div class="imageRow flex" v-for="(images, row) in pictures.images" :key="'row' + row">
                <div @mouseenter="handleEnter" @mouseleave="handleLeave" class="hovered" v-for="(i, key) in images" :key="'images' + key">
                    <img-component :src="i[0]" :alt="key + '1'"/>
                    <img-component class="hover abs hide t300" :src="i[1]" :alt="key + '2'"/>
                </div>
            </div>
        </div>
        <video-component :preview="preview" :localized-preview="true" :video-height="getHeight()" ref="videoHeight"/>
    </div>
</template>

<script>
import configs from "@/globals/configs.json";
import VideoComponent from "@/components/dekstop/Video.vue";
import ImgComponent from "@/components/dekstop/Img.vue";

export default {
    name: 'raumedic-vue',
    components: {ImgComponent, VideoComponent},
    data() {
        return {
            pictures: configs.images.raumedic.pictures,
            preview: configs.videos.raumedic.preview,
            full: configs.videos.raumedic.full,
        }
    },
    methods: {
        handleEnter(event) {
            event.currentTarget.querySelector(".hover").classList.remove("hide");
        },
        handleLeave(event) {
            event.currentTarget.querySelector(".hover").classList.add("hide");
        },
        getHeight() {
            if(window.innerWidth >= 1920)
                return "1080px";
            return 1080 * window.innerWidth / 1920 + "px";
        }
    }
}
</script>

<style lang="scss">
#raumedic {
    max-width: 2000px;

    .description {
        width: 509px;
        font-weight: 400;
    }

    .videoDescription {
        width: 642px;
        margin: 253px 0 45px 0;
        font-weight: 400;
    }

    .main {
        width: 1920px;
        margin: auto;

        .left-side {
            width: 500px;
            max-width: 500px;
            margin-left: 30%;
        }
    }

    img.main {
        margin: -308px 0 0 600px;
        width: 1441px;
        position: relative;
        z-index: -1;
    }

    .images {
        display: flex;
        flex-direction: column;

        .hovered {
            margin: 10px;
            position: relative;

            img {
                width: 100%;
                height: auto;

                &.hover {
                    left: 0;
                }

                &.hide {
                    opacity: 0;
                }
            }
        }
    }

}
</style>
