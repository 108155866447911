<template>
    <div class="menu">
        <router-link v-for="nav in navigations" :to="'/' + nav" :key="nav">{{ $t(nav + ".name") }}</router-link>
    </div>
</template>
<script>
import configs from "@/globals/configs.json";

export default {
    name: 'navigation-mobile-component',
    data() {
        return {
            navigations: configs.mobileNavigation
        }
    },
}
</script>

<style lang="scss">

#mobile {
    .router-link-active {
        color: var(--color-accent);
    }

    .menu {
        margin: 25px auto 50px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        height: auto;
        width: 90vw;

        a {
            margin: 3px 8px;
        }
    }
}
</style>