<template>
    <div id="showreels" class="flex column">
        <h1>{{ $t("navigation.showreels") }}</h1>
        <div class="years">
            <div class="year" v-for="(year, i) in getYears()" :key="`year${i}`">
                <div :data-year="year" :class="['number t500', year === currentYear? 'current':'',year < currentYear?'previous':'']"
                     @mouseenter="handleMouseIn"
                     @mouseleave="handleMouseOut" @click="handleClick">
                    {{ year }}
                    <template v-if="year<currentYear">
                        <div class="line t300"/>
                        <div class="reelContainer t300" v-if="showReelVideos[year]?.reels">
                            <video-component class="yearPreview" :full="showReelVideo" :play-button="false" :loading="false"
                                             :key="showReelVideo" v-for="(showReelVideo, i) in showReelVideos[year]?.reels">
                                <p class="reel t500 hide">Breakdown {{ i + 1 }}</p>
                            </video-component>
                        </div>
                    </template>
                </div>
                <video-component class="yearPreview" :preview="showReelVideos[year].preview" :play-button="false"
                                 v-if="showReelVideos[year]?.preview" :loading="false"/>
            </div>
        </div>
    </div>
</template>

<script>
import configs from "@/globals/configs.json";
import VideoComponent from "@/components/dekstop/Video.vue";

export default {
    name: 'showreels-vue',
    components: {VideoComponent},
    data() {
        return {
            startYear: configs.startYear,
            futureYears: configs.futureYears,
            showReelVideos: configs.showreels,
            currentYear: new Date().getFullYear(),
            fadeInFastId: undefined,
            fadeInSlowId: undefined,
        }
    },
    methods: {
        getYears() {
            const years = [];

            for (let year = this.startYear; year <= this.currentYear + this.futureYears; year++) {
                years.push(year);
            }
            return years;
        },
        handleClick(event, currentElement = undefined) {
            const element = currentElement !== undefined ? currentElement : event.target;
            if (element.classList.contains("previous")) {
                let hasActive = element.classList.contains("activated");
                let actives = element.parentNode.parentNode.querySelector(".number.activated");
                if (actives != null) {
                    actives.classList.remove("activated")
                    actives.parentNode.querySelector(".video-component")?.classList.remove("activated")
                    actives.parentNode.querySelectorAll(".reel")?.forEach((reel, i) => {
                        setTimeout(function () {
                            reel?.classList.remove("activated")
                            setTimeout(function () {
                                reel?.classList.add("hide")
                            }, 600)
                        }, i * 100)
                    })
                }
                if (!hasActive) {
                    element.classList.add("activated")
                    setTimeout(function () {
                        element.parentNode.querySelector(".video-component")?.classList.add("activated")
                    }, 500)
                    setTimeout(function () {
                        element.parentNode.querySelectorAll(".reel")?.forEach((reel, i) => {
                            reel?.classList.remove("hide")
                            setTimeout(function () {
                                reel?.classList.add("activated")
                            }, (i + 1) * 100)
                        })
                    }, 500)
                }
            }
        },
        handleMouseIn(event) {
            const element = event.target;
            this.fadeInFastId = setTimeout(function () {
                element.classList.add("mouseOver");
            }, 300);
        },
        handleMouseOut(event, otherElement = undefined) {
            const element = otherElement !== undefined ? otherElement : event.target;
            clearTimeout(this.fadeInFastId);
            clearTimeout(this.fadeInSlowId);
            element.classList.remove("mouseOver");
        }
    },
    mounted() {
        this.handleClick(undefined, document.querySelector(".number.previous"));
    },
}
</script>

<style lang="scss">


#showreels {
    margin: 300px auto 500px;
    width: 40vw;

    .years {
        margin-top: 28px;

        .year {
            width: fit-content;

            .number {
                position: relative;
                max-height: 28px;
                color: var(--color-future-years);
                margin-bottom: 4px;
                cursor: default;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;

                &.previous {
                    cursor: pointer;
                }


                .reelContainer {
                    max-height: 200px;
                    position: absolute;
                    width: auto;

                    .reel {
                        margin: 20px 0 0 250px;
                        background: var(--color-secondary);
                        padding: 15px 25px;
                        border-radius: 10px;
                        opacity: 0;
                        width: 100%;

                        &.hide {
                            display: none;
                        }

                        p {
                            margin: 0;
                            cursor: pointer;
                        }
                    }
                }

                .reel.activated {
                    margin: 20px 0 0 100px;
                    display: flex;
                    opacity: 1;
                    cursor: pointer;

                    &:hover {
                        box-shadow: 0 0 20px 1px var(--color-accent);
                    }
                }

                &.activated {
                    max-height: 100%;

                    + .yearPreview video.preview {
                        opacity: 1;
                        left: 0;
                    }
                }

                &.mouseOver, &.activated {
                    &.previous {
                        color: var(--color-accent);
                    }
                }

                &.activated {
                    .line {
                        opacity: 1;
                        width: 200px;
                    }
                }
            }

            > .yearPreview video.preview {
                mix-blend-mode: lighten;
                position: absolute;
                height: 990px;
                opacity: 0;
                right: 0;
                top: 970px;
                z-index: -50;
                margin: 0 0 auto auto;
            }

            .line {
                opacity: 0;
                border: 1px solid var(--color-accent);
                width: 0;
                border-image: linear-gradient(to right, var(--color-accent), transparent) 2;
            }

            .number.previous {
                color: var(--color-text);
            }
        }

        > :last-child .number {
            color: var(--color-last-year);
        }
    }

    h1 {
        font-weight: 400;
        font-size: 36px;
        line-height: 43px;
        color: var(--color-accent);
    }
}
</style>