<template>
    <div class="navigation-container b500 t500" @mouseenter="handleNavigationOver" @mouseleave="handleNavigationOut"
         v-if="navigations[this.metaId]">
        <div class="lines t500">
            <div class="line-top t500"></div>
            <div class="line-middle t500"></div>
            <div class="line-bottom t500"></div>
        </div>
        <div class="flex column j-start pages t500" :key="metaId" ref="pages">
            <router-link @mouseenter="handleMouseOver" @mouseleave="handleMouseOut" @click="handleClick" @loadeddata="setActive"
                         v-for="(nav, i) in navigations[this.metaId]"
                         :key="`navigation${i}${metaId}`"
                         class="hover t500 child"
                         :to="'/' + nav">{{ $t('navigation.' + nav) }}
            </router-link>
        </div>
    </div>
</template>
<script>

import configs from "@/globals/configs.json";

export default {
    name: 'navigation-component',
    data() {
        return {
            mouseOut: undefined,
            mouseOver: undefined,
            navigationOut: undefined,
            navigationOver: undefined,
            noMouseOver: false,
            metaId: undefined,
            navigations: configs.navigation,
        }
    },
    watch: {
        '$route'() {
            clearTimeout(this.navigationOut);
            this.fetchData()
        }
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.metaId = this.$route.meta.id;
            const self = this;
            setTimeout(function () {
                const element = document.querySelector(".pages > a[aria-current='page']");
                const container = document.querySelector(".navigation-container");
                if (container != null) {
                    self.handleNavigationOver(undefined, container);
                    this.navigationOut = setTimeout(function () {
                        self.handleNavigationOut(undefined, document.querySelector(".navigation-container"));
                    }, 1500);
                }
                if (element != null)
                    self.moveUp(element)
            }, 301);
        },
        handleNavigationOver(event, element = undefined) {
            const target = element ? element : event.currentTarget;
            clearTimeout(this.navigationOut);
            target.classList.add("show");
        },
        handleNavigationOut(event, element = undefined) {
            const target = element ? element : event?.currentTarget;
            if (this.$route.meta.navigationOut) {
                this.navigationOut = setTimeout(function () {
                    target.classList.remove("show");
                }, 2000);
            }
        },
        setActive(event) {
            if (event.currentTarget.match("a[aria-current='page']"))
                this.handleClick({target: event.currentTarget})
        },
        handleMouseOver(event) {
            const target = event.currentTarget;

            if (this.noMouseOver)
                return
            const height = 350;
            const pages = this.$refs.pages;
            const children = Array.from(pages.children);
            const index = children.indexOf(target);

            const top = pages.style.marginTop ? parseInt(pages.style.marginTop.replace(/(-?\d+)px/, "$1")) : 0;

            clearTimeout(this.mouseOut);
            clearTimeout(this.mouseOver);
            this.mouseOver = setTimeout(function () {
                const topLine = document.querySelector(".line-top");
                topLine?.style?.setProperty("height", (height + index * 50 + top) + "px");
                const bottomLine = document.querySelector(".line-bottom");
                bottomLine?.style?.setProperty("height", (height - index * 50 - top) + "px");
            }, 200);
        },
        handleMouseOut() {
            clearTimeout(this.mouseOut);
            this.mouseOut = setTimeout(function () {
                const topLine = document.querySelector(".line-top");
                topLine?.style?.removeProperty("height");
                const bottomLine = document.querySelector(".line-bottom");
                bottomLine?.style?.removeProperty("height");
            }, 500);
        },
        handleClick(event) {
            const middleLine = document.querySelector(".line-middle");
            middleLine.classList.add("click");
            setTimeout(function () {
                middleLine.classList.remove("click");
            }, 1000);
            this.moveUp(event.currentTarget)
        },
        moveUp(target) {
            const self = this;
            this.noMouseOver = true;
            const parent = target.parentNode;
            const children = Array.from(parent.children);
            const index = children.indexOf(target);
            setTimeout(function () {
                const topLine = document.querySelector(".line-top");
                topLine?.style?.removeProperty("height");
                const bottomLine = document.querySelector(".line-bottom");
                bottomLine?.style?.removeProperty("height");
                const pages = document.querySelector(".pages");
                pages?.style?.setProperty("margin-top", -index * 50 + "px");
                setTimeout(function () {
                    self.noMouseOver = false;
                }, 500);
            }, 500);
        }
    }
}
</script>

<style lang="scss">

.navigation-container {
    position: sticky;
    z-index: 10;
    margin: -720px 0 auto 0;
    top: 100px;
    width: 0;

    &.show {
        .lines {
            margin: 0 -120px 0 40px;
        }

        .pages {
            opacity: 1;
        }
    }

    .pages {
        left: 100px;
        top: 354px;
        position: absolute;
        min-width: 150px;
        height: fit-content;
        opacity: 0;

        a.hover {
            font-size: 14px;
            margin-bottom: 33px;
            line-height: 17px;
            color: var(--color-navigation);

            &:hover {
                color: var(--color-navigation-hover);
            }

            &[aria-current='page'] {
                color: var(--color-text);
            }
        }
    }

    .lines {
        width: 0;
        margin-left: 0;
        top: 3px;
        position: relative;

        .line-top, .line-middle, .line-bottom {
            border: 1px solid var(--color-accent);
            border-radius: 2px;
            width: 0;
        }

        .line-top {
            position: relative;
            left: 30px;
            bottom: 3px;
            height: 350px;
            border-image: linear-gradient(to top, var(--color-accent), transparent) 2;
        }

        .line-middle {
            width: 20px;
            left: 21px;
            position: relative;
            height: 20px;
            rotate: 45deg;
            border-width: 2px;
            border-left: none;
            border-bottom: none;
            border-radius: 0px;

            &.click {
                border-width: 20px;
            }
        }

        .line-bottom {
            position: relative;
            left: 30px;
            top: 3px;
            height: 350px;
            border-image: linear-gradient(to bottom, var(--color-accent), transparent) 2;
        }
    }
}
</style>