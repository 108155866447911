<template>
    <Transition appear>
        <div class="language-switcher b400">
            <div class="radio-group">
                <template v-for="(lang, i) in $i18n.availableLocales" :key="`Lang${i}`">
                    <div :class="{'inner':true,'right': i === 0,'left': i === $i18n.availableLocales.length - 1}">
                        <input v-model="$i18n.locale" hidden type="radio" :id="`choice${i}`" :value="lang" name="languageSelection"
                               @change="onChange">
                        <label :class="{'t300 full':true, 'active': $i18n.locale === lang}" :for="`choice${i}`">{{ $t(lang) }}</label>
                        <label :class="{'t300 small':true}"
                               :for="`choice${i}`">{{ lang }}</label>
                    </div>
                    <div class="line" v-if="i < $i18n.availableLocales.length - 1"/>
                </template>
            </div>
        </div>
    </Transition>
</template>

<script>
import {saveLanguage} from "@/i18n";
import {useI18n} from "vue-i18n";
import {Transition} from "vue";

export default {
    name: 'switch-language-component',
    components: Transition,
    methods: {
        useI18n,
        onChange(event) {
            saveLanguage(event.currentTarget.value)
        }
    }
}
</script>

<style lang="scss">

#mobile {
    .language-switcher {
        position: sticky;
        top: 10px;
        left: 0;
        right: 0;
        padding: 30px;
        z-index: 10000;
        height: auto;
        width: fit-content;
    }
}

.language-switcher {
    position: relative;
    right: 0;
    bottom: 10vh;
    width: 5%;
    margin: 0 5% 0 auto;
    height: 0;
}

.radio-group {
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    .inner {
        top: -9px;
        position: relative;

        label {
            font-size: 12px;

            &.full:not(.active), &.full:not(.active) + .small {
                cursor: pointer;
            }

            &.active {
                color: var(--color-text);
            }

            &:active {
                color: var(--color-accent);
            }

        }

        .full:not(.active) + .small {
            margin: auto;
            display: block;
            width: fit-content;
            opacity: 1;
        }

        .small {
            opacity: 0;
            color: var(--color-secondary);
        }

        .full:not(.active) {
            opacity: 0;
            z-index: 6;
        }


        &.right * {
            position: absolute;
            margin-right: 0;
            right: 1px;
        }

        &.left * {
            position: absolute;
            margin-left: 0;
            left: 1px;
        }
    }

    .line {
        margin: auto 2px;
        height: 14px;
        border: 0.5px solid var(--color-secondary);
    }
}

#desktop .radio-group .inner:hover {
    color: var(--color-text);
    width: unset;

    .full:not(.active) + .small {
        opacity: 0;
    }

    .full {
        opacity: 1;
        z-index: 6;
    }
}

.v-enter-active,
.v-leave-active {
    transition: opacity 2s ease, transform 1s ease-in-out;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.label-enter-active,
.label-leave-active {
    transition: color 2s ease, transform 1s ease-in-out;
}

</style>