<template>
    <showreels-vue/>
</template>

<script>

import ShowreelsVue from "@/views/desktop/start/Showreels.vue";

export default {
    name: 'showreels-mobile-vue',
    components: {ShowreelsVue},
}
</script>

<style lang="scss">
#mobile #showreels {
    width: 80vw;
    margin: 200px auto 25vw;

    .years .year {
        video {
            max-height: 80vh;

            &.preview {
                bottom: -200px;
                top: unset;
            }
        }

        .number .reelContainer {
            left: 0;
            right: 0;
            bottom: 0;
            position: fixed;
            max-width: 100vw;

            .reel {
                margin: 0 auto 3vh;

                &.activated {
                    margin: 0 auto 2vh;
                }
            }
        }
    }
}

</style>