<template>
    <div id="ampiria" class="dark-background">
        <div class="main">
            <div class="left-side">
                <h2>{{ $t("works.ampiria.name") }}</h2>
                <p v-html="$t('works.ampiria.description')"/>
                <video-component :preview="videos.intro" :full="videos.intro"/>
            </div>
            <carousel-component :items-size="speakerFade.length" class="right">
                <img-component v-for="(speaker, i) in speakerFade" :key="`speaker${i}`" :src="speaker"
                               :class="{ 'active': currentIndex === i }" class="t1000 changing" alt="ampiria"/>
            </carousel-component>
        </div>
        <switch-component :switch-data="interactiveData">
            <template #first>
                <video-component :preview="interactiveData.first.data" :full="interactiveData.first.data"/>
            </template>
            <template #second>
                <div class="left section" @mouseenter="handleEnter" @mouseleave="handleLeave">
                    <img-component :src="interactiveData.second.data['left']?.hover"
                                   class="overlay shining cold hiding t1000"/>
                    <img-component :src="interactiveData.second.data['left']?.main" class="main shining warm t1000"/>
                </div>
                <div class="middle">
                    <div class="top section" @mouseenter="handleEnter" @mouseleave="handleLeave">
                        <img-component :src="interactiveData.second.data['middle']?.hover" class="overlay hiding t1000"/>
                        <img-component :src="interactiveData.second.data['middle']?.main" class="main t1000"/>
                        <img-component :src="interactiveData.second.data['bottom']?.main" class="bottom"/>
                    </div>
                </div>
                <div class="right section" @mouseenter="handleEnter" @mouseleave="handleLeave">
                    <img-component :src="interactiveData.second.data['right']?.hover" class="overlay hiding t1000"/>
                    <img-component :src="interactiveData.second.data['right']?.main" class="main t1000"/>
                </div>
            </template>
        </switch-component>
        <img-component class="page" src="ampiria/1_low"/>
        <video-component :preview="videos.end" class="last"/>
    </div>
</template>

<script>
import VideoComponent from "@/components/dekstop/Video.vue";
import configs from "@/globals/configs.json";
import CarouselComponent from "@/components/dekstop/Carousel.vue";
import SwitchComponent from "@/components/dekstop/Switch.vue";
import ImgComponent from "@/components/dekstop/Img.vue";
import TopComponent from "@/components/dekstop/Top.vue";

export default {
    name: 'ampiria-vue',
    components: {TopComponent, ImgComponent, SwitchComponent, CarouselComponent, VideoComponent},
    data() {
        return {
            speakerHoverTime: undefined,
            speakerFade: configs.images.ampiria.speakerFade,
            currentIndex: 0,
            videos: configs.videos.ampiria,
            interactiveData: configs.images.ampiria.interactive
        }
    },
    methods: {
        handleEnter(event) {
            event.currentTarget.querySelector("img.overlay")?.classList.remove("hiding");
            let element = event.currentTarget.querySelector("img.main");
            element?.classList.add("hiding");
        },
        handleLeave(event) {
            event.currentTarget.querySelector("img.main")?.classList.remove("hiding");
            let element = event.currentTarget.querySelector("img.overlay");
            element?.classList.add("hiding");
        }
    }
}
</script>

<style lang="scss">


#ampiria {
    .left-side {
        width: 500px;
        min-width: 500px;
        margin-left: 30%;
    }

    video.preview {
        mix-blend-mode: lighten;
    }

    > .main {
        display: flex;
        width: 1920px;
        margin: auto;
        justify-content: flex-start;
    }

    img.page {
        mix-blend-mode: lighten;
        max-width: 1500px;
        margin: 150px auto;
        display: block;
    }

    .switch {
        .second.slot {
            justify-content: space-evenly;
        }

        .middle img.bottom {
            bottom: -60px;
            position: absolute;
            z-index: 10;
            width: 110%;
            margin: auto;
        }

        .section {

            position: relative;

            &.top {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &:has(.shining.main):before {
                content: "";
                border-radius: 50%;
                width: 20px;
                height: 20px;
                margin: auto;
                left: 17vw;
                top: 20%;
                transform: rotate(10deg);
                transition: all 0.5s ease;
                display: block;
                opacity: 0.3;
                position: absolute;
                z-index: -5;
                background: white;
                box-shadow: 0 0 10px 5px rgb(255, 255, 255), /* inner white */
                0 0 3vw 2vw rgba(255, 255, 255, 0.7), /* inner white */
                0 0 5vw 4vw rgba(225, 165, 103, 0.63), /* middle magenta */
                0 0 10vw 9vw rgba(211, 97, 55, 0.47); /* outer cyan */
            }

            &:has(.shining.overlay:hover):before {
                box-shadow: 0 0 10px 5px rgb(255, 255, 255), /* inner white */
                0 0 3vw 2vw rgba(255, 255, 255, 0.7), /* inner white */
                0 0 5vw 4vw rgba(103, 225, 203, 0.63), /* middle magenta */
                0 0 10vw 9vw rgba(55, 146, 211, 0.47); /* outer cyan */
            }

            img {
                opacity: 1;
                max-width: 33vw;
                position: relative;

                &.hiding {
                    opacity: 0;
                }

                &.overlay {
                    z-index: 6;
                }

                &.main {
                    position: absolute;
                    z-index: 5;
                    left: 0;
                    top: 0;
                }
            }
        }
    }
}

</style>
