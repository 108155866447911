<template>
    <data-vue/>
</template>

<script>

import DataVue from "@/views/desktop/Data.vue";

export default {
    name: 'data-mobile-vue',
    components: {DataVue},
}
</script>

<style lang="scss">
#mobile #data {
    h1 {
        margin-bottom: 25px;
    }

    p.max-width {
        max-width: 100%;
        margin-bottom: 25px;
    }
}
</style>