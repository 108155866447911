<template>
    <div v-if="mobile" :key="mobile" id="mobile">
        <switch-language-component/>
        <navigation-mobile-component/>
        <router-view name="subMenu"/>
        <router-view name="mobile"/>
    </div>
    <div v-else id="desktop">
        <navigation-component/>
        <div class="flex t1000" id="body">
            <menu-component/>
            <div class="page-content">
                <router-view/>
            </div>
        </div>
        <footer-component>
            <switch-language-component/>
        </footer-component>
    </div>
</template>

<script>


import FooterComponent from "@/components/dekstop/Footer.vue";
import SwitchLanguageComponent from "@/components/dekstop/SwitchLanguage.vue";
import MenuComponent from "@/components/dekstop/Menu.vue";
import NavigationComponent from "@/components/dekstop/Navigation.vue";
import NavigationMobileComponent from "@/components/mobile/NavigationMobile.vue";

export default {
    name: 'app',
    data() {
        return {
            mobile: this.isMobile()
        }
    },
    components: {NavigationMobileComponent, NavigationComponent, MenuComponent, SwitchLanguageComponent, FooterComponent},
    created() {
        window.addEventListener("resize", this.isMobile);
    },
    unmounted() {
        window.removeEventListener("resize", this.isMobile);
    },
    watch: {
        '$route.meta.active'() {
            this.setTitle()
        },
        '$i18n.locale'() {
            this.setTitle()
        }
    },
    methods: {
        setTitle() {
            let title = this.$t("title");
            document.title = title
            if (this.$route.meta.active) {
                let subtitle = this.$t(this.$route.meta.id + "." + this.$route.meta.active + ".name");
                if (this.$route.meta.id === this.$route.meta.active)
                    subtitle = this.$t(this.$route.meta.id + ".name")
                if (subtitle)
                    document.title = subtitle + " | " + title
            }
        },
        isMobile() {
            this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || screen.width <= 1400 || window.innerWidth <= 1400
            return this.mobile;
        }
    }
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-to {
    top: 0;
}

#app {
    width: 100%;
    height: 100%;
    position: relative;
    min-height: 100vh;
}

#mobile {
    margin: 0 0 10vh
}

#body {
    margin: auto;
    flex-direction: column;
}

.page-content, .mobile-content {
    margin: 100px auto 20vh auto;
    max-width: 100vw;
    display: grid;
    position: relative;

    > * {
        grid-area: 1/1;
    }
}

body {
    font-family: Barlow, Helvetica, Arial, sans-serif;
    overflow-y: scroll;
    overflow-x: hidden;
    min-width: max-content;
    scroll-behavior: smooth;
    width: 100vw;
    background-image: var(--gradient-background);

    &:has(.dark-background) {
        background-image: var(--gradient-background-dark);
    }
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: var(--color-background);
}

::-webkit-scrollbar-thumb {
    background: var(--color-accent);
}

</style>
