<template>
    <sketch-vue/>
</template>
<script>
import configs from "@/globals/configs.json";
import {defineAsyncComponent} from "vue";
import SketchVue from "@/views/desktop/shortfilms/Sketch.vue";

export default {
    name: 'sketch-mobile-vue',
    components: {SketchVue},
}
</script>

<style lang="scss">
#mobile #sketch {
    img{
        max-height: 80vh;
        width: auto;
    }
}

</style>