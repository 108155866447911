<template>
    <img :src="getUrl()" :alt="alt" ref="image" :key="$i18n.locale + src">
</template>
<script>

export default {
    name: 'img-component',
    data() {
        return {
            fullscreen: false,
        }
    },
    props: {
        src: {
            type: String,
            required: true,
        },
        fadeIn: {
            type: Boolean,
            default: true,
            required: false,
        },
        localized: {
            type: Boolean,
            default: false,
        },
        alt: {
            type: String,
            default: "image",
        }
    },
    methods: {
        getUrl() {
            const loc = this.localized ? "." + this.$i18n.locale : "";
            let file_ext = this.src.split(".");
            let file = file_ext[0];
            let ext = file_ext.length > 1 ? file_ext[1] : "jpg";

            return process.env.VUE_APP_MEDIA_ROOT + file + loc + "." + ext;
        },
    }
}
</script>

<style scoped lang="scss">

</style>
